import { useEffect, useState } from "react";
import { platformAdminConstants, platformAdminUrlConstants } from "./Constants";
import { useDispatch, useSelector } from "react-redux";
import {
	setSelectedRowData,
	toggleLoadingView,
	toggleToastView,
} from "../../redux/reducer/appReducer";
import { platformAdminService } from "./Service";
import { getTableData } from "../tableComponent/tableComponent.slice";
import { CommonContainer } from "../../utils/CommonFunction";
import { checkNumericExpression, imageUpload } from "../../utils/helper";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

export const PlatformAdminUserContainer = () => {
	const { showEditForm, showAddForm, showViewForm, selectedRowData } =
		useSelector((state) => state.app);
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState("1");
	const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
		CommonContainer();

	const companyInfo = {
		parentId: secureLocalStorage.getItem(storageConstants?.companyId),
		companyName: secureLocalStorage.getItem(storageConstants?.companyName),
		userName: "",
		firstName: "",
		lastName: "",
		email: "",
		mobile: "",
		role: platformAdminConstants?.companyRoles[0]?.id,
		image: "",
		active: true,
	};
	const [rowData, setRowData] = useState(companyInfo);
	const [resetData, setResetData] = useState({});
	const [imageToRemove, setImageToRemove] = useState([]);

	const getSelectedRowData = async () => {
		dispatch(toggleLoadingView(true));
		const data = await platformAdminService.getSelectedPlatformAdminDataService(
			selectedRowData?._id,
			secureLocalStorage.getItem(storageConstants?.companyId)
		);
		setRowData(data?.result);
		setResetData(data?.result);
		dispatch(toggleLoadingView(false));
	};

	useEffect(() => {
		if (showEditForm || showViewForm) {
			getSelectedRowData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeTabIndex = (event, newValue) => {
		setTabIndex(newValue);
	};

	const onChangeFirstName = (event) => {
		// setRowData({ ...rowData, firstName: event.target.value });
		const str = event.target.value;
		setRowData({
			...rowData,
			firstName: str.charAt(0).toUpperCase() + str.slice(1),
		});
	};
	const onChangeLastName = (event) => {
		// setRowData({ ...rowData, lastName: event.target.value });
		const str = event.target.value;
		setRowData({
			...rowData,
			lastName: str.charAt(0).toUpperCase() + str.slice(1),
		});
	};
	const onChangeEmail = (event) => {
		setRowData({ ...rowData, email: event.target.value });
	};
	const onChangeMobile = (event) => {
		if (
			checkNumericExpression(event.target.value) &&
			event.target.value.length <= 10
		) {
			setRowData({ ...rowData, mobile: event.target.value });
		} else if (event.target.value === "") {
			setRowData({ ...rowData, mobile: "" });
		}
	};
	const onChangeRole = (event) => {
		setRowData({ ...rowData, role: event.target.value });
	};

	const onChangeStatus = (event) => {
		setRowData({ ...rowData, active: event.target.value });
	};

	const onChangeProfileImage = async (event) => {
		dispatch(toggleLoadingView(true));
		const file = event.target.files[0];
		if (!file) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "No image selected. Please try again.",
					isError: true,
				})
			);
			dispatch(toggleLoadingView(false));
			return;
		}
		const res = await imageUpload(file);
		if (res.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "Please upload again",
					isError: true,
				})
			);
		} else {
			setRowData({ ...rowData, image: res?.result?.uploadedFiles[0]?.fileUrl });
		}
		dispatch(toggleLoadingView(false));
	};

	const onRemoveImage = async () => {
		const image = rowData.image;
		imageToRemove.push({ image: image });
		setImageToRemove(imageToRemove);

		setRowData({
			...rowData,
			image: "",
		});
	};


	const onClickSave = async () => {
		if (rowData?.firstName.trim() === "" ||
			rowData?.role.trim() === "" ||
			rowData?.email.trim() === "" ||
			rowData?.mobile.trim() === ""
		) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "* Please fill the required fields",
					isError: true,
				})
			);
		} else {
			dispatch(toggleLoadingView(true));
			let response;
			if (showAddForm) {
				response = await platformAdminService.savePlatformAdminDataService(
					rowData
				);
			} else {
				response = await platformAdminService.updatePlatformAdminDataService(
					rowData
				);
			}
			if (response?.result?.length === 0) {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: true,
					})
				);
			} else {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: false,
					})
				);
				await dispatch(
					getTableData(
						// platformAdminUrlConstants.getTableDataUrl)
						`${platformAdminUrlConstants.getTableDataUrl
						}/${secureLocalStorage.getItem(storageConstants?.companyId)}`
					)
				);
				onClickShowTable();
			}
			dispatch(toggleLoadingView(false));
		}
	};
	const onClickReset = () => {
		setRowData(resetData);
	};

	const onClickPlatformAdminAddForm = () => {
		onClickAddForm();
	};
	const onClickPlatformAdminEditForm = () => {
		onClickEditForm();
	};
	const onClickPlatformAdminViewForm = () => {
		onClickViewForm();
	};

	const onClickDelete = async () => {
		dispatch(toggleLoadingView(true));
		const response = await platformAdminService.deletePlatformAdminDataService(
			selectedRowData?._id,
			secureLocalStorage.getItem(storageConstants?.companyId)
		);
		dispatch(setSelectedRowData({}));
		if (response.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: true,
				})
			);
		} else {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: false,
				})
			);
			await dispatch(
				getTableData(
					// platformAdminUrlConstants.getTableDataUrl)
					`${platformAdminUrlConstants.getTableDataUrl
					}/${secureLocalStorage.getItem(storageConstants?.companyId)}`
				)
			);
			dispatch(toggleLoadingView(false));
		}
	};

	return {
		onChangeFirstName,
		onChangeLastName,
		onChangeEmail,
		onChangeMobile,
		onChangeRole,
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		showEditForm,
		onClickSave,
		onClickReset,
		rowData,
		onClickDelete,
		onClickPlatformAdminAddForm,
		onClickPlatformAdminEditForm,
		onClickPlatformAdminViewForm,
		onChangeProfileImage,
		onChangeStatus,
		onRemoveImage
	};
};
