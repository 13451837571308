import { useEffect, useState } from "react";
import { companyConstants, companyUrlConstants } from "./Constants";
import { useDispatch, useSelector } from "react-redux";
import {
	setSelectedRowData,
	toggleLoadingView,
	toggleToastView,
} from "../../redux/reducer/appReducer";
import { companyService } from "./Service";
import { getTableData } from "../tableComponent/tableComponent.slice";
import { CommonContainer } from "../../utils/CommonFunction";
import { checkNumericExpression, imageUpload } from "../../utils/helper";

export const CompanyContainer = () => {
	const { showEditForm, showAddForm, showViewForm, selectedRowData } =
		useSelector((state) => state.app);
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState("1");
	const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
		CommonContainer();

	const companyInfo = {
		companyName: "",
		userName: '',
		firstName: '',
		lastName: '',
		email: "",
		mobile: "",
		userLimit: 0,
		paymentType: "",
		price: 0,
		duration: 0,
		role: companyConstants.companyRoles[0].id,
		subscriptionStartDate: "",
		subscriptionEndDate: "",
		subscriptionStatus: companyConstants.subscriptionStatusValues[0].id,
		image: "",
		active: true,
	};
	const [rowData, setRowData] = useState(companyInfo);
	const [resetData, setResetData] = useState({});
	const [pricingPlanData, setPricingPlanData] = useState([]);
	const [paymentTypeData, setPaymentTypeData] = useState([]);
	const [imageToRemove, setImageToRemove] = useState([]);

	const getSelectedRowData = async () => {
		dispatch(toggleLoadingView(true));
		const data = await companyService.getSelectedCompanyDataService(
			selectedRowData?._id
		);
		setRowData(data?.result);
		setResetData(data.result);
		dispatch(toggleLoadingView(false));
	};

	const getPricingPlanData = async () => {
		const data = await companyService.getPricingPlanService();
		setPricingPlanData(data.result || []);
		if (data?.result?.length > 0) {
			const trailPlan = data?.result?.find((item) => item?.title === "Trail");
			if (trailPlan && showAddForm) {
				setRowData((prevData) => ({
					...prevData,
					paymentType: trailPlan._id,
					duration: trailPlan.duration,
					userLimit: trailPlan.userLimit,
					price: trailPlan.price,
					subscriptionStartDate: new Date(),
					subscriptionEndDate: new Date().setDate(
						new Date().getDate() + trailPlan.duration
					),
				}));
			}

			// Create payment type data for dropdown
			const temp = data.result.map((each) => ({
				id: each._id,
				title: each.title,
			}));
			setPaymentTypeData(temp);
		}
	};

	useEffect(() => {
		if (showEditForm || showViewForm) {
			getSelectedRowData();
		}
		if (showAddForm || showEditForm || showViewForm) {
			getPricingPlanData();
		}
	}, [showEditForm, showViewForm, showAddForm]);

	const onChangeTabIndex = (event, newValue) => {
		setTabIndex(newValue);
	};

	const onChangeCompanyName = (event) => {
		setRowData({ ...rowData, companyName: event.target.value });
	};
	const onChangeFirstName = (event) => {
		setRowData({ ...rowData, firstName: event.target.value });
	};
	const onChangeLastName = (event) => {
		setRowData({ ...rowData, lastName: event.target.value });
	};
	const onChangeEmail = (event) => {
		setRowData({ ...rowData, email: event.target.value });
	};
	const onChangeMobile = (event) => {
		// setRowData({ ...rowData, mobile: event.target.value });
		if (
			checkNumericExpression(event.target.value) &&
			event.target.value.length <= 10
		) {
			setRowData({ ...rowData, mobile: event.target.value });
		} else if (event.target.value === "") {
			setRowData({ ...rowData, mobile: "" });
		}
	};

	const onChangeStatus = (event) => {
		setRowData({ ...rowData, active: event.target.value });
	};

	// const onChangeUserLimit = (event) => {
	//   if (checkNumericExpression(event.target.value)) {
	//     const value = parseInt(event.target.value);
	//     setRowData({ ...rowData, userLimit: value.toString() });
	//   } else if (event.target.value === "") {
	//     setRowData({ ...rowData, userLimit: 0 });
	//   }
	// };
	// const onChangePrice = (event) => {
	//   if (checkNumericExpression(event.target.value)) {
	//     const value = parseInt(event.target.value);
	//     setRowData({ ...rowData, price: value.toString() });
	//   } else if (event.target.value === "") {
	//     setRowData({ ...rowData, price: 0 });
	//   }
	// };

	const onChangePaymentType = (event) => {
		const data = pricingPlanData.filter(
			(each) => each._id === event.target.value
		)[0];

		setRowData({
			...rowData,
			paymentType: event.target.value,
			duration: data.duration,
			userLimit: data.userLimit,
			price: data.price,
			subscriptionStartDate: new Date(),
			subscriptionEndDate: new Date().setDate(
				new Date().getDate() + data.duration
			),
		});
	};
	const onChangeSubscriptionStatus = (event) => {
		setRowData({ ...rowData, subscriptionStatus: event.target.value });
	};
	const onChangeRole = (event) => {
		setRowData({ ...rowData, role: event.target.value });
	};
	const onChangeProfileImage = async (event) => {
		dispatch(toggleLoadingView(true));
		const file = event.target.files[0];
		if (!file) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "No image selected. Please try again.",
					isError: true,
				})
			);
			dispatch(toggleLoadingView(false));
			return;
		}
		const res = await imageUpload(file);
		if (res.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "Please upload again",
					isError: true,
				})
			);
		} else {
			setRowData({ ...rowData, image: res?.result?.uploadedFiles[0]?.fileUrl });
		}
		dispatch(toggleLoadingView(false));
	};

	const onRemoveImage = async () => {
		const image = rowData.image;
		imageToRemove.push({ image: image });
		setImageToRemove(imageToRemove);

		setRowData({
			...rowData,
			image: "",
		});
	};

	const onClickSave = async () => {
		if (rowData?.companyName.trim() === "" ||
			rowData?.email.trim() === "" ||
			rowData?.firstName.trim() === "" ||
			rowData?.mobile.trim() === ""
		) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "* Please fill the required fields",
					isError: true,
				})
			);
		} else {
			dispatch(toggleLoadingView(true));
			let response;
			if (showAddForm) {
				response = await companyService.saveCompanyDataService(rowData);
			} else {
				response = await companyService.updateCompanyDataService(rowData);
			}
			if (response.result.length === 0) {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: true,
					})
				);
			} else {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: false,
					})
				);
				await dispatch(getTableData(companyUrlConstants.getTableDataUrl));
				onClickShowTable();
			}
			dispatch(toggleLoadingView(false));
		}
	};
	const onClickReset = () => {
		setRowData(resetData);
	};

	const onClickCompanyAddForm = () => {
		onClickAddForm();
	};
	const onClickCompanyEditForm = () => {
		onClickEditForm();
	};
	const onClickCompanyViewForm = () => {
		onClickViewForm();
	};

	const onClickDelete = async () => {
		dispatch(toggleLoadingView(true));
		const response = await companyService.deleteCompanyDataService(
			selectedRowData?._id
		);
		dispatch(setSelectedRowData({}));
		if (response.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: true,
				})
			);
		} else {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: false,
				})
			);
			await dispatch(getTableData(companyUrlConstants.getTableDataUrl));
			dispatch(toggleLoadingView(false));
		}
	};

	return {
		onChangeCompanyName,
		onChangeEmail,
		tabIndex,
		onChangeTabIndex,
		onChangeRole,
		onChangePaymentType,
		onChangeSubscriptionStatus,
		showViewForm,
		showEditForm,
		onClickSave,
		onClickReset,
		rowData,
		onClickDelete,
		paymentTypeData,
		onClickCompanyAddForm,
		onClickCompanyEditForm,
		onClickCompanyViewForm,
		onChangeProfileImage,
		onChangeStatus,
		onChangeMobile,
		onChangeFirstName,
		onChangeLastName,
		onRemoveImage
	};
};
